<script lang="ts" setup>
  const event = useRequestEvent()
  // Set the status code to 404 for a custom 404 page
  setResponseStatus(event, 404)
</script>

<template>
  <main class="container">
  <h2>404 page not found</h2>
  </main>
</template>

<style lang="postcss" scoped>
main {
  text-align: center;
}
</style>
